/* eslint-disable tailwindcss/no-custom-classname */
import '../styles/sass/modules/_home.scss';

import Catalog from '../modules/catalog/Catalog';

function Home() {
  return (
    <div>
      <div className="homeBackground -z-1 absolute inset-0 min-h-screen">
        <div className="fixed inset-0">
          <Catalog />
        </div>
      </div>
    </div>
  );
}

export default Home;
