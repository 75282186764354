import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit';

import { getProfile } from '../storeService/authenticationService';
import { type ProfileType } from '../type';

// async thunk
export const requestGetProfile = createAsyncThunk(
  '@authentication/getProfile',
  async (_reqData, thunkAPI) => {
    try {
      return await getProfile();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// reducer
export type DataProfile = ProfileType | null;

export interface InitialState {
  data: DataProfile;
  loading: Record<string, boolean>;
  isError?: boolean;
}

export const initialState: InitialState = {
  data: null,
  loading: {},
  isError: false,
};

export const authentication = createSlice({
  name: '@authentication',
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<DataProfile>) => {
      state.data = action.payload ?? null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestGetProfile.pending, (state) => {
        state.loading = {
          fullPage: true,
        };
      })
      .addCase(requestGetProfile.fulfilled, (state, action) => {
        state.loading.fullPage = false;

        state.data = action.payload;
      });
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state) => {
        state.loading = {};
        state.isError = true;
      }
    );
  },
});

export const { reducer, actions } = authentication;
export const { setProfile } = actions;
export default reducer;
