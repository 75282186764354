import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { toast } from 'react-hot-toast';

import { type SettingData } from '../../type';
import { getLogo, updateLogo } from '../storeService/settingService';

export interface InitialState {
  data: SettingData;
}

// async thunk
export const thunkGetSettingData = createAsyncThunk(
  '@setting/getLogo',
  async (_reqData, thunkAPI) => {
    try {
      return await getLogo();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const thunkUpdateSetting = createAsyncThunk(
  '@setting/updateLogo',
  async (reqData: FormData, thunkAPI) => {
    try {
      return await updateLogo(reqData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Reducer
export const initialState: InitialState = {
  data: null,
};

export const settingSlice = createSlice({
  name: '@setting',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    let loadId: string | undefined;

    builder
      // handler get setting
      .addCase(thunkGetSettingData.pending, () => {})
      .addCase(thunkGetSettingData.fulfilled, (state, action) => {
        if (action.payload != null) {
          state.data = action.payload;
        }
      })
      .addCase(thunkGetSettingData.rejected, () => {
        toast.error('Lỗi không thể tải dữ liệu');
      })

      // handler update setting
      .addCase(thunkUpdateSetting.pending, () => {
        loadId = toast.loading('Đang cập nhật');
      })
      .addCase(thunkUpdateSetting.fulfilled, (state, action) => {
        toast.success('Cập nhật thành công');
        state.data = action.payload;
      })
      .addMatcher(
        isAnyOf(thunkUpdateSetting.fulfilled, thunkUpdateSetting.rejected),
        () => {
          toast.dismiss(loadId);
        }
      );
  },
});

export const { reducer, actions } = settingSlice;

export default reducer;
