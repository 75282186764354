import { useEffect } from 'react';

import { getCatalogView } from '../../redux/storeService/catalogService';
import combineImg from '../../shared/methods/combineImg';

// import { useEffect } from 'react';

function Catalog() {
  useEffect(() => {
    const js = [
      'scripts/handy/main.js',
      'scripts/search/book_config.js',
      'scripts/flipHtml5.hiSlider2.min.js',
      'scripts/slideJS.js',
    ];

    void getCatalogView().then((resp) => {
      if (resp.image != null) {
        const homeBackground: HTMLDivElement | null =
          document.querySelector('.homeBackground');

        if (homeBackground != null) {
          homeBackground.style.backgroundImage = `url(${combineImg({
            folder: resp.folder,
            pathUrl: resp.image,
          })})`;
        }
      }

      if (resp.images != null) {
        // Tạo data catalog
        const catalogList = resp.images.map((img) => {
          const url = combineImg({ folder: resp.folder, pathUrl: img });

          return {
            l: url,
            t: url,
          };
        });

        // Tạo và thêm script config vào body trước khi render ra catalog
        const stringJS = `<script>
            bookConfig.totalPageCount = ${catalogList.length}
            bookConfig.appLogoIcon = '${combineImg({
              pathUrl: resp.settings?.logo ?? '',
            })}'
            var catalogPages = ${JSON.stringify(catalogList)}
        </script>`;

        const scriptConfig = document
          .createRange()
          .createContextualFragment(stringJS);
        document.body.append(scriptConfig);

        // Thêm các script chính để hiển thị và chạy các chức năng của catalog
        const tmp = document.getElementById('tmpContainer');

        if (tmp == null) {
          js.forEach((item) => {
            const script = document.createElement('script');

            script.src = item;

            document.body.append(script);
          });
        }
      }
    }).catch;
  }, []);

  return <div></div>;
}

export default Catalog;
