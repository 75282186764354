import LoginFormContext from '../LoginFormContext';
import ResetPassFormContext from '../ResetPassFormContext';

export const formData = {
  login: {
    title: 'Đăng nhập',
    btnText: 'Đăng nhập',
    formContext: LoginFormContext,
  },
  reset: {
    title: 'Lấy lại mật khẩu',
    btnText: 'Gửi yêu cầu',
    formContext: ResetPassFormContext,
  },
};
