import { type ChangePasswordFormType } from '../../modules/admin/settingCatalog/type';
import { type LoginFormData } from '../../modules/login/other/type';
import axiosInstance from '../../shared/interceptors/axios';
import {
  type ChangePasswordResponse,
  type LoginResponse,
  type ProfileResponse,
} from './responseTypes';

const sendLogin = async (req: LoginFormData) => {
  const resp = await axiosInstance.post('auth/login', req);

  const data: LoginResponse = resp.data;

  return data;
};

const getProfile = async () => {
  const resp = await axiosInstance.post('auth/profile');

  const data: ProfileResponse = resp.data ?? null;

  return data;
};

const sendLogout = async () => {
  const resp = await axiosInstance.post('auth/logout');

  return resp.data;
};

const changePassword = async (req: ChangePasswordFormType) => {
  const resp = await axiosInstance.post('auth/change-password', req);

  const data: ChangePasswordResponse = resp.data;

  return data;
};

const resetPassword = async (req: { email: string }) => {
  const resp = await axiosInstance.post('auth/reset-password', req);

  return resp.data;
};

export { changePassword, getProfile, resetPassword, sendLogin, sendLogout };
