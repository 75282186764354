import React, { lazy, useCallback } from 'react';

import Loadable from '../components/Loader/Loadable';
import { useAppDispatch, useAppSelector } from '../redux/hook';
import { hiddenImageGallery } from '../redux/slice/imageGallerySlice';

const GalleryImage = Loadable(
  lazy(async () => import('../components/GalleryImage'))
);

function GalleryImgLayout() {
  const dispatch = useAppDispatch();

  const { data, index } = useAppSelector((state) => state.imageGallery);

  const onHiddenImageGallery = useCallback(() => {
    dispatch(hiddenImageGallery());
  }, []);
  return (
    <>
      {data != null ? (
        <GalleryImage
          photosData={data}
          index={index}
          clickClose={onHiddenImageGallery}
        />
      ) : (
        ''
      )}
    </>
  );
}

export default GalleryImgLayout;
