/* eslint-disable tailwindcss/no-custom-classname */

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../components/admin/Header';
import Nav from '../components/admin/Nav';
import LayoutLoading from '../components/loading/LayoutLoading';
import { useAppDispatch, useAppSelector } from '../redux/hook';
import { profileData } from '../redux/selector/authenticationSelector';
import { requestGetProfile } from '../redux/slice/authenticationSlice';
import { getAccessToken } from '../shared/helpers/token';

function AdminLayout({ children }: React.HTMLProps<HTMLElement>) {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const data = useAppSelector(profileData);

  const token = getAccessToken();

  const invalidToken = token === null;

  useEffect(() => {
    if (invalidToken) {
      navigate('/admin/login');
    }

    if (data == null && token != null) {
      void dispatch(requestGetProfile());
    }
  }, []);

  if (invalidToken) {
    return <></>;
  }

  return (
    <>
      {data != null ? (
        <div className="min-h-full md:h-full">
          <Nav />

          <Header data={data} />
          <main
            id="main"
            className={
              'mainWrap mt-[var(--header-height)] min-h-[calc(100vh_-_var(--header-height))] gap-5 bg-gray-200 p-3 md:p-5 lg:rounded-tl-lg' +
              (window.innerWidth < 1024 ? ' full-screen' : '')
            }
          >
            {children}
          </main>
        </div>
      ) : (
        <LayoutLoading />
      )}
    </>
  );
}

export default AdminLayout;
