import React from 'react';
import Skeleton from 'react-loading-skeleton';

import WrapSkeleton from '../WrapSkeleton';

function HomeSkeleton() {
  return (
    <WrapSkeleton>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-[2fr_1fr]">
        <div>
          <Skeleton
            containerClassName="flex flex-col sm:flex-row gap-4 mb-4"
            count={2}
            className="h-40"
          />
          <Skeleton className="h-96 lg:h-[600px]" />
        </div>
        <div>
          <Skeleton
            containerClassName="flex flex-col gap-4 mb-4"
            count={2}
            className="h-[70px]"
          />
          <Skeleton className="h-96 lg:h-[600px]" />
        </div>
      </div>
    </WrapSkeleton>
  );
}

export default HomeSkeleton;
