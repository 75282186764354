import React, { memo } from 'react';

import { type DataProfile } from '../../redux/slice/authenticationSlice';
import MenuIcon from '../../shared/icons/MenuIcon';
import UserIcon from '../../shared/icons/UserIcon';
import toggleNavbar from '../../shared/methods/toggleNavbar';
import HeaderUserSkeleton from '../skeleton/admin/HeaderUserSkeleton';

interface Props {
  data: DataProfile;
}

function Header({ data }: Props) {
  return (
    <header className="fixed left-0 top-0 z-[15] h-[var(--header-height)] w-full rounded-md bg-white pr-3 md:pr-5">
      <div className="flex h-full items-center">
        <div className="flex items-center justify-between pl-3 md:w-[var(--nav-admin-w-lg)] md:pl-5">
          <h3 className="hidden text-xl font-semibold text-black-500 lg:block">
            Trang chủ
          </h3>
          <div
            className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-lg bg-primaryLight text-primary transition-all hover:bg-primary hover:text-primaryLight"
            onClick={toggleNavbar}
          >
            <MenuIcon />
          </div>
        </div>
        <div className="flex flex-1">
          {data != null ? (
            <div className="ml-auto flex h-[var(--height-comp)] items-center gap-2 rounded-3xl bg-primaryLight px-2">
              <div className="text-primary">
                <UserIcon className="h-8 w-8" />
              </div>
              <h3 className=" ">
                {' '}
                <b>Xin chào</b>, {data.last_name}
              </h3>
            </div>
          ) : (
            <div className="ml-auto w-[164px]">
              <HeaderUserSkeleton />
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export default memo(Header);
