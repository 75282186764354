import { type SyntheticEvent } from 'react';

export default function onPromise<T>(
  promise: (event: SyntheticEvent) => Promise<T>
) {
  return (event: SyntheticEvent) => {
    event.preventDefault();
    if (promise != null) {
      promise(event).catch((error) => {
        console.log('Unexpected error', error);
      });
    }
  };
}
