import '../../styles/sass/components/_table.scss';

import { Outlet } from 'react-router-dom';

function Admin() {
  return (
    <>
      <Outlet />
    </>
  );
}

export default Admin;
