/* eslint-disable tailwindcss/no-custom-classname */
import './styles/global.css';
import './styles/sass/index.scss';
import './styles/sass/lib/catalog_custom/index.scss';
import 'react-loading-skeleton/dist/skeleton.css';

import { Fragment } from 'react';
import { Toaster } from 'react-hot-toast';
import { Route, Routes } from 'react-router-dom';

import DefaultLayout from './layouts/DefaultLayout';
import GalleryImgLayout from './layouts/GalleryImgLayout';
import { privateRoutes, publicRoutes, type RoutesData } from './routes';

const App = () => {
  return (
    <div className="__app">
      <GalleryImgLayout />
      <Toaster />
      <Routes>
        {[...privateRoutes, ...publicRoutes].map((route, i) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let Layout: any;

          if (route.layout != null && route.layout !== undefined) {
            Layout = route.layout;
          } else if (route.layout === null) {
            Layout = Fragment;
          } else {
            Layout = DefaultLayout;
          }

          const Page = route.component;

          const childrenRoutes = (childrenList?: RoutesData[]) => {
            return childrenList != null && childrenList.length > 0
              ? childrenList.map((childRoute, i2) => {
                  const ChildPage = childRoute.component;
                  return (
                    <Route
                      key={i2}
                      path={childRoute.path}
                      element={<ChildPage />}
                    >
                      {childrenRoutes(childRoute.childrenRoutes)}
                    </Route>
                  );
                })
              : '';
          };

          return (
            <Route
              key={i}
              path={route.path}
              element={
                <Layout>
                  <Page />
                </Layout>
              }
            >
              {childrenRoutes(route.childrenRoutes)}
            </Route>
          );
        })}
      </Routes>
    </div>
  );
};

export default App;
