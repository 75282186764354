/* eslint-disable tailwindcss/no-custom-classname */
import '../../styles/sass/components/admin/_nav.scss';

import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { NavLink, useLocation } from 'react-router-dom';

import menuList from '../../data/admin/menuList';
import { sendLogout } from '../../redux/storeService/authenticationService';
import configs from '../../shared/configs/configs';
import { removeAccessToken } from '../../shared/helpers/token';
import CloseSmallIcon from '../../shared/icons/CloseSmallIcon';
import toggleNavbar from '../../shared/methods/toggleNavbar';
import onPromise from '../../shared/utils/onPromise';

function Nav() {
  const math = useLocation();

  const [loading, setLoading] = useState(false);

  const logout = async () => {
    const loadId = toast.loading('Đang đăng xuất');

    setLoading(true);

    try {
      await sendLogout();

      removeAccessToken();

      toast.remove(loadId);

      window.location.href = configs.baseName + '/admin/login';
    } catch (error) {}
  };

  return (
    <nav
      id="navbar"
      className={`adminNav${window.innerWidth >= 1024 ? ' open' : ''}`}
    >
      <div className="nav-overlay" onClick={toggleNavbar} />
      <div
        className="navbarWrap bgColor1 px-4"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="flex items-center justify-between overflow-hidden pb-5 text-black-500">
          <h3 className="whitespace-nowrap text-xl font-semibold">
            Bảng điều khiển
          </h3>
          <div onClick={toggleNavbar}>
            <CloseSmallIcon className="h-6 w-6" />
          </div>
        </div>
        <div className="navbarBody mt-4">
          <ul className="navbarList">
            {menuList.map((item, index) => {
              const Icon = item.icon;

              return (
                <li
                  key={index}
                  className="navbarItem"
                  onClick={() => {
                    if (window.innerWidth < 1024) {
                      toggleNavbar();
                    }
                  }}
                >
                  {index + 1 < menuList.length ? (
                    <NavLink
                      to={item.path}
                      className={({ isActive }) => {
                        return isActive
                          ? item.path === '' &&
                            math.pathname !== configs.baseName + '/admin'
                            ? 'navbarLink'
                            : 'navbarLink active'
                          : 'navbarLink';
                      }}
                    >
                      <div className="navbarItemWrap text-slate-800">
                        <div className="wrapIcon">
                          <Icon />
                        </div>
                        <h4>{item.title}</h4>
                      </div>
                    </NavLink>
                  ) : (
                    <div
                      className={
                        'navbarLink' + (loading ? ' pointer-events-none' : '')
                      }
                      onClick={onPromise(logout)}
                    >
                      <div className="navbarItemWrap text-slate-800">
                        <div className="wrapIcon">
                          <Icon />
                        </div>
                        <h4>{item.title}</h4>
                      </div>
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
