/* eslint-disable tailwindcss/no-custom-classname */
import '../../styles/sass/modules/_login.scss';

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import LoginForm from '../../modules/login/LoginForm';
import { getAccessToken } from '../../shared/helpers/token';

const Login = () => {
  const navigate = useNavigate();

  const token = getAccessToken();

  const validToken = token != null;

  useEffect(() => {
    if (validToken) {
      navigate('/admin');
    }
  }, []);

  if (validToken) {
    return <></>;
  }

  return (
    <div className="loginBackground -z-1 absolute inset-0 min-h-screen">
      <div className="layout-container flex h-full items-center justify-center ">
        <LoginForm />
      </div>
    </div>
  );
};

export default Login;
