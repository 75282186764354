import axios from 'axios';

import axiosInstance from '../../shared/interceptors/axios';
import {
  type CatalogDetail,
  type CatalogRemoveImgReq,
  type CatalogReqType,
  type CatalogUpdateReq,
  type CatalogUploadImgReq,
  type SlugReqType,
} from '../type';
import {
  type CatalogCreateResp,
  type CatalogRemoveImgResp,
  type CatalogRespData,
  type CatalogSlugResp,
  type CatalogUploadImgResp,
  type CatalogViewResp,
} from './responseTypes';

const getCatalogView = async () => {
  //   const resp = await axios.post(configs.baseUrl + '/nb-auto-erp');
  const resp = await axios.post(
    window.location.origin + window.location.pathname
  );

  return resp.data as CatalogViewResp;
};

/* *************************************************** */

const getCatalogList = async (req: CatalogReqType) => {
  const resp = await axiosInstance.post('catalog', req);

  const data: CatalogRespData = resp.data ?? null;

  return data;
};

/* *************************************************** */

const getCatalogDetail = async (id: number) => {
  const resp = await axiosInstance.post(`catalog/edit/${id}`);

  const data: CatalogDetail = resp.data ?? null;

  return data;
};

/* *************************************************** */

const createCatalog = async (req: FormData) => {
  const resp = await axiosInstance.post(`catalog/store`, req);

  const data: CatalogCreateResp = resp.data;

  return data;
};

/* *************************************************** */

const makeSlug = async (req: SlugReqType) => {
  const resp = await axiosInstance.post('catalog/slug', req);

  return resp.data as CatalogSlugResp;
};

/* *************************************************** */

const updateCatalog = async (req: CatalogUpdateReq) => {
  const resp = await axiosInstance.post(`catalog/update/${req.id}`, req);
  return resp.data as {
    message: string;
  };
};

/* *************************************************** */

const deleteCatalog = async (id: string | number) => {
  const resp = await axiosInstance.delete(`catalog/delete/${id}`);

  return resp.data as {
    message: string;
  };
};

// ========================= || Img catalog service || ========================= //

const uploadImageCatalog = async (req: CatalogUploadImgReq) => {
  const resp = await axiosInstance.post(`catalog/update/${req.id}`, req.data);

  const respData: CatalogUploadImgResp = resp.data;

  return {
    type: req.type,
    data: respData.data,
    message: respData.message,
  };
};

const removeCatalogImg = async (req: CatalogRemoveImgReq) => {
  const resp = await axiosInstance.post(`catalog/update/${req.id}`, req);

  const respData: {
    message: string;
  } = resp.data;

  const respPayload: CatalogRemoveImgResp = {
    message: respData.message,
    dataRemove: req.listRemove,
  };

  return respPayload;
};

export {
  createCatalog,
  deleteCatalog,
  getCatalogDetail,
  getCatalogList,
  getCatalogView,
  makeSlug,
  removeCatalogImg,
  updateCatalog,
  uploadImageCatalog,
};
