import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import Input from '../../components/formControl/Input';
import { type ResetPassFormData } from './other/type';

function ResetPassFormContext() {
  const {
    control,
    formState: { errors },
  } = useFormContext<ResetPassFormData>();

  return (
    <>
      <Controller
        control={control}
        name="email"
        render={({ field }) => (
          <Input
            type="email"
            title="Email"
            placeholder="Nhập email của bạn"
            msg={errors.email?.message}
            {...field}
          />
        )}
      />
    </>
  );
}

export default ResetPassFormContext;
