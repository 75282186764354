import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type GalleryData } from '../../components/GalleryImage';

export interface InitialState {
  data: GalleryData[] | null;
  index?: number;
}

export const initialState: InitialState = {
  data: null,
  index: 1,
};

export const imageGallerySlice = createSlice({
  name: '@imageGallerySlice',
  initialState,
  reducers: {
    showImageGallery: (state, action: PayloadAction<InitialState>) => {
      const { data, index } = action.payload;

      state.data = data;
      state.index = index;
    },

    hiddenImageGallery: (state) => {
      state.data = null;
    },
  },
});

export const { reducer, actions } = imageGallerySlice;
export const { showImageGallery, hiddenImageGallery } = actions;
export default reducer;
