/* eslint-disable @typescript-eslint/no-explicit-any */
import { configureStore } from '@reduxjs/toolkit';

// import logger from 'redux-logger';
import authenticationReducer from './slice/authenticationSlice';
import catalogReducer from './slice/catalogSlice';
import imageGalleryReducer from './slice/imageGallerySlice';
import settingReducer from './slice/settingSlice';

// export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

const store = configureStore({
  reducer: {
    imageGallery: imageGalleryReducer,
    authentication: authenticationReducer,
    catalog: catalogReducer,
    setting: settingReducer,
  },
  //   middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
