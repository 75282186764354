import configs from '../configs/configs';

interface Data {
  folder?: string | number;
  pathUrl: string;
}

function combineImg({ folder = '', pathUrl }: Data) {
  return `${configs.baseUrl}/uploads/${folder}/${pathUrl}`;
}

export default combineImg;
