interface ConfigsType {
  baseUrl: string;
  baseName: string;
  token: string;
}

const configs: ConfigsType = {
  baseUrl: '',
  baseName: '',
  token: '@token',
};

export default configs;
