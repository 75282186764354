/* eslint-disable tailwindcss/no-custom-classname */
interface BookLoadingProps {
  title?: string;
}

export const BookLoading = ({ title = 'Đang tải' }: BookLoadingProps) => {
  return (
    <div className="bookLoading flex flex-col items-center justify-center">
      <div className="book">
        <figure></figure>
        <figure></figure>
        <figure></figure>
      </div>

      <h1>{title}</h1>
    </div>
  );
};

function LayoutLoading() {
  return (
    <div className="layoutLoading fixed inset-0">
      <BookLoading />
    </div>
  );
}

export default LayoutLoading;
