import React from 'react';

function SettingIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fillOpacity=".85"
          d="m34.585 22.574-2.631-2.25a14.456 14.456 0 0 0 0-4.645l2.631-2.25a1.286 1.286 0 0 0 .374-1.414l-.036-.104a17.83 17.83 0 0 0-3.202-5.54l-.073-.085a1.29 1.29 0 0 0-1.41-.382l-3.267 1.161a14.157 14.157 0 0 0-4.005-2.314l-.631-3.415A1.287 1.287 0 0 0 21.298.303l-.108-.02a18.15 18.15 0 0 0-6.389 0l-.108.02a1.287 1.287 0 0 0-1.037 1.033l-.635 3.431a14.137 14.137 0 0 0-3.977 2.306l-3.29-1.169a1.286 1.286 0 0 0-1.411.382l-.072.084a17.92 17.92 0 0 0-3.203 5.54l-.036.105a1.29 1.29 0 0 0 .374 1.415l2.664 2.274a14.136 14.136 0 0 0-.185 2.294c0 .771.06 1.543.185 2.294l-2.664 2.274a1.287 1.287 0 0 0-.374 1.414l.036.105a17.874 17.874 0 0 0 3.203 5.54l.072.085a1.29 1.29 0 0 0 1.41.382l3.29-1.17a14.04 14.04 0 0 0 3.978 2.307l.635 3.43a1.287 1.287 0 0 0 1.037 1.034l.108.02c2.113.38 4.276.38 6.389 0l.108-.02a1.288 1.288 0 0 0 1.037-1.033l.63-3.415a14.061 14.061 0 0 0 4.006-2.315l3.267 1.162a1.286 1.286 0 0 0 1.41-.382l.073-.085a17.92 17.92 0 0 0 3.202-5.54l.036-.105a1.281 1.281 0 0 0-.374-1.406Zm-5.484-6.42a11.365 11.365 0 0 1 0 3.704l-.265 1.611 3.001 2.568a14.873 14.873 0 0 1-1.712 2.957l-3.728-1.322-1.262 1.037a11.227 11.227 0 0 1-3.186 1.84l-1.53.574-.72 3.898c-1.135.128-2.28.128-3.415 0l-.72-3.906-1.518-.582a11.24 11.24 0 0 1-3.162-1.837l-1.262-1.04L5.87 26.99a15.182 15.182 0 0 1-1.712-2.957l3.034-2.592-.262-1.607a11.568 11.568 0 0 1-.148-1.828c0-.615.048-1.23.148-1.828l.262-1.607-3.034-2.592A14.866 14.866 0 0 1 5.87 9.022l3.752 1.334 1.262-1.04a11.242 11.242 0 0 1 3.162-1.837l1.523-.574.719-3.906a15.167 15.167 0 0 1 3.415 0l.72 3.897 1.53.575a11.283 11.283 0 0 1 3.186 1.84l1.262 1.037 3.729-1.322a15.18 15.18 0 0 1 1.711 2.957L28.84 14.55l.26 1.604ZM18 10.533a7.072 7.072 0 1 0 0 14.143 7.072 7.072 0 0 0 0-14.143Zm3.182 10.253A4.485 4.485 0 0 1 18 22.104c-1.202 0-2.33-.47-3.183-1.318a4.484 4.484 0 0 1-1.317-3.182c0-1.201.47-2.33 1.317-3.182A4.467 4.467 0 0 1 18 13.104c1.2 0 2.33.466 3.182 1.318a4.485 4.485 0 0 1 1.318 3.182c0 1.201-.47 2.33-1.318 3.182Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h36v36H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SettingIcon;
