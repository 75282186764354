import axiosInstance from '../../shared/interceptors/axios';
import { type SettingData } from '../../type';

const getLogo = async () => {
  const resp = await axiosInstance.get('settings');

  return resp.data as SettingData;
};

const updateLogo = async (req: FormData) => {
  const resp = await axiosInstance.post('settings', req);

  return resp.data as SettingData;
};

export { getLogo, updateLogo };
