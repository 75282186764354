/* eslint-disable tailwindcss/no-custom-classname */
import React, { useImperativeHandle, useRef } from 'react';

export type ButtonType = 'primary' | 'light' | 'error';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  viewType?: ButtonType;
  heightType?: 'large' | 'small';
  inline?: boolean;
}

interface ButtonRef {
  click: () => void;
}

const style = {
  primary: ' bg-primary text-white',
  light: ' bg-primaryLight text-primary',
  blue: ' bg-blue-500 text-white',
  error: 'bg-red-100 text-red-600',
};

const heightStyle = {
  large: ' h-[46px] sm:h-[54px]',
  small: ' h-[46px]',
};

const Button: React.ForwardRefRenderFunction<ButtonRef, ButtonProps> = (
  { children, className, viewType, heightType, inline = false, ...props },
  ref
) => {
  const btnRef = useRef(null);

  useImperativeHandle(ref, () => ({
    ...ref,
    click() {
      const el = btnRef.current as HTMLButtonElement | null;
      if (el != null) {
        el.click();
      }
    },
  }));

  return (
    <button
      ref={btnRef}
      className={`cursor-custom relative isolate flex select-none items-center justify-center gap-3 overflow-hidden whitespace-nowrap rounded-lg px-4 py-3 font-semibold before:absolute before:left-0 before:right-auto before:top-0 before:z-[1] before:h-full before:w-0 before:bg-white before:opacity-[0.1] before:transition-all before:duration-500 before:content-[''] hover:before:left-auto hover:before:right-0 hover:before:w-full md:px-8 ${
        inline ? 'w-auto' : 'w-full'
      } ${className ?? ''}${style[viewType ?? 'primary']} ${
        heightStyle[heightType ?? 'large']
      }`}
      {...props}
    >
      {children}
    </button>
  );
};

export default React.forwardRef(Button);
