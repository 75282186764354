import { memo, type ReactNode } from 'react';

interface Props {
  children?: ReactNode;
}

function DefaultLayout({ children }: Props) {
  return (
    <>
      {/* <Header /> */}

      <main>{children}</main>
    </>
  );
}

export default memo(DefaultLayout);
