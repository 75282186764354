import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import Input from '../../components/formControl/Input';
import { type LoginFormData } from './other/type';

function LoginFormContext() {
  const {
    control,
    formState: { errors },
  } = useFormContext<LoginFormData>();
  return (
    <>
      <Controller
        control={control}
        name="email"
        render={({ field }) => (
          <Input
            type="email"
            title="Email"
            placeholder="Nhập email của bạn"
            msg={errors.email?.message}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="password"
        render={({ field }) => (
          <Input
            type="password"
            title="Mật khẩu"
            placeholder="Nhập mật khẩu của bạn"
            msg={errors.password?.message}
            {...field}
          />
        )}
      />
    </>
  );
}

export default LoginFormContext;
