import React from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

// import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import NavigationScroll from './layouts/NavigationScroll';
import store from './redux/store';

function Root() {
  //   const [state, setState] = useState<string | null>(null);

  //   useEffect(() => {
  //     void axios.get('data.json').then((res) => {
  //       const respData = res.data as {
  //         sub_folder: string;
  //       } | null;
  //       setState(respData?.sub_folder ?? null);
  //     }).catch;
  //   }, []);

  //   function loadFile(url: string) {
  //     const xhr = new XMLHttpRequest();

  //     xhr.onload = () => {};
  //     xhr.onerror = () => {};
  //     xhr.open('GET', url, false);
  //     xhr.send(null);
  //     return JSON.parse(xhr.response ?? 'null');
  //   }

  //   const resp = loadFile('data.json') as { sub_folder: string };

  //   const baseName = resp.sub_folder ?? '';

  return (
    <>
      <Router basename={''}>
        <Provider store={store}>
          <SkeletonTheme
            inline
            baseColor="#f4f4f5"
            highlightColor="#fafafa"
            borderRadius={8}
          >
            <NavigationScroll>
              <App />
            </NavigationScroll>
          </SkeletonTheme>
        </Provider>
      </Router>
    </>
  );
}

export default Root;
