import React from 'react';
import Skeleton from 'react-loading-skeleton';

import WrapSkeleton from '../WrapSkeleton';

function CatalogDetailSkeleton() {
  return (
    <WrapSkeleton>
      <Skeleton className="h-12 md:h-20" containerClassName="mb-4 block" />
      <div className="grid grid-cols-1 gap-4 md:grid-cols-[2fr_1fr]">
        <div className="flex flex-col gap-4">
          <Skeleton className="h-48 sm:h-96 md:h-[600px]" />
          <Skeleton className="h-36 md:h-72" />
        </div>
        <div className="flex flex-col gap-4">
          <Skeleton className="h-24 md:h-36" />
          <Skeleton className="h-44 md:h-80" />
          <Skeleton className="h-44 md:h-80" />
        </div>
      </div>
    </WrapSkeleton>
  );
}

export default CatalogDetailSkeleton;
