import CatalogIcon from '../../shared/icons/CatalogIcon';
import HomeIcon from '../../shared/icons/HomeIcon';
import LogOutIcon from '../../shared/icons/LogOutIcon';
import SettingIcon from '../../shared/icons/SettingIcon';

interface MenuData {
  title: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  path: string;
}

const menuList: MenuData[] = [
  {
    title: 'Trang chủ',
    icon: HomeIcon,
    path: '',
  },
  {
    title: 'Catalog',
    icon: CatalogIcon,
    path: 'catalog',
  },
  {
    title: 'Cài đặt',
    icon: SettingIcon,
    path: 'setting',
  },
  {
    title: 'Đăng xuất',
    icon: LogOutIcon,
    path: '',
  },
];

export default menuList;
