import configs from '../configs/configs';
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from './localStorage';

type GetToken = () => string | null | undefined;

const accessToken = configs.token;

// Set Token
export const setAccessToken = <T>(value: T) => {
  setLocalStorage(accessToken, value);
};
// End Set Token

// Get Token
export const getAccessToken: GetToken = () => getLocalStorage(accessToken);
// End Get Token

// Remove Token
export const removeAccessToken = () => {
  removeLocalStorage(accessToken);
};
export const removeRefreshToken = () => {};
// End Remove Token

export const validToken = () => {
  if (getAccessToken() != null) {
    return true;
  } else {
    return false;
  }
};
