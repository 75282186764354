import axios, { type AxiosError } from 'axios';
import { toast } from 'react-hot-toast';

import configs from '../configs/configs';
import { getAccessToken, removeAccessToken } from '../helpers/token';

const axiosInstance = axios.create({
  baseURL: `${configs.baseUrl}/api/v1/`,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const headerConfig = {
      Authorization: `Bearer ${getAccessToken() ?? ''}`,
      Accept: 'application/json' as string,
    };

    Object.assign(config.headers, headerConfig);

    return config;
  },
  async (error) => Promise.reject(error)
);

// let refresh = false;

axiosInstance.interceptors.response.use(
  async (resp) => {
    return resp;
  },
  async (error: AxiosError) => {
    const statusCode = error.response?.status;

    if (statusCode === 401) {
      if (typeof getAccessToken() === 'string') {
        removeAccessToken();
      }

      window.location.href = configs.baseName + '/admin/login';
    }

    if ([500, 404].includes(statusCode ?? 0)) {
      toast.error('Lỗi hệ thống. Vui lòng thử lại');
    }

    return Promise.reject<AxiosError>(error);
  }
);

export default axiosInstance;
