import type React from 'react';
import { lazy } from 'react';

import Loadable from '../components/Loader/Loadable';
import CatalogDetailSkeleton from '../components/skeleton/admin/CatalogDetailSkeleton';
// skeleton
import CatalogListSkeleton from '../components/skeleton/admin/CatalogListSkeleton';
import HomeSkeleton from '../components/skeleton/admin/HomeSkeleton';
import AdminLayout from '../layouts/AdminLayout';
import Admin from '../pages/admin/Admin';
import CatalogRoute from '../pages/admin/catalog/CatalogRoute';
import Login from '../pages/admin/Login';
import NotFound from '../pages/admin/NotFound';
import Home from '../pages/Home';

// component lazy
const Index = Loadable(
  lazy(async () => import('../pages/admin')),
  HomeSkeleton
);
const Catalog = Loadable(
  lazy(async () => import('../pages/admin/catalog/Catalog')),
  CatalogListSkeleton
);
const CatalogCreate = Loadable(
  lazy(async () => import('../pages/admin/catalog/CatalogCreate')),
  CatalogDetailSkeleton
);
const CatalogEdit = Loadable(
  lazy(async () => import('../pages/admin/catalog/CatalogEdit/index')),
  CatalogDetailSkeleton
);
const Setting = Loadable(lazy(async () => import('../pages/admin/Setting')));

export interface RoutesData {
  path?: string;
  component: (props: JSX.IntrinsicAttributes) => JSX.Element;
  layout?: ((props: React.HTMLProps<HTMLElement>) => JSX.Element) | null;
  index?: boolean;
  childrenRoutes?: RoutesData[];
}

const publicRoutes: RoutesData[] = [
  { path: '/admin/login', component: Login, layout: null },
  { path: '*', component: NotFound },
];

const privateRoutes: RoutesData[] = [
  {
    path: '/',
    component: Home,
    childrenRoutes: [
      {
        path: ':slug',
        component: Home,
      },
    ],
  },
  {
    path: 'admin',
    component: Admin,
    layout: AdminLayout,
    childrenRoutes: [
      {
        path: '',
        component: Index,
      },
      {
        path: 'catalog',
        component: CatalogRoute,
        childrenRoutes: [
          {
            path: '',
            component: Catalog,
          },
          {
            path: 'create',
            component: CatalogCreate,
          },
          {
            path: ':catalogId',
            component: CatalogEdit,
          },
        ],
      },
      {
        path: 'setting',
        component: Setting,
      },
    ],
  },
];

export { privateRoutes, publicRoutes };
