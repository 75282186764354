import React from 'react';

interface Props extends React.HTMLProps<HTMLLabelElement> {}

function Label({ children, className, ...props }: Props) {
  return (
    <label
      htmlFor=""
      className={
        'mb-2 inline-block font-medium text-slate-600 ' + (className ?? '')
      }
      {...props}
    >
      {children}
    </label>
  );
}

export default Label;
