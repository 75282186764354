/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable tailwindcss/no-custom-classname */
import React, { forwardRef, memo, useState } from 'react';
import { type FieldError } from 'react-hook-form/dist/types';

import EyeOffIcon from '../../shared/icons/EyeOffIcon';
import EyeOnIcon from '../../shared/icons/EyeOnIcon';
import Label from '../admin/Label';

interface Props extends React.HTMLProps<HTMLInputElement> {
  type?: 'email' | 'text' | 'password';
  title?: string;
  placeholder?: string;
  msg?: string | null | FieldError;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  name?: string;
  value?: string;
  rowGap?: boolean;
  limit?: number;
  padding?: string;
}

export const wrapInputClassCss = 'relative w-full';

export const inputClassCss =
  'w-full rounded-md border-[1px] border-solid border-slate-300 focus:border-darkMain focus:shadow-ct1';

export const textLimitClassCss =
  'absolute -top-7 right-1 text-sm text-gray-500';

function Input({
  type = 'text',
  title,
  placeholder,
  msg,
  name,
  value,
  rowGap = true,
  limit,
  padding = 'p-3 sm:p-4',
  ...props
}: Props) {
  const [show, setShow] = useState(false);

  return (
    <>
      {title !== undefined ? (
        <div className="text-left">
          {' '}
          <Label htmlFor="">{title}</Label>{' '}
        </div>
      ) : (
        ''
      )}

      <div className={'text-left' + (rowGap ? ' mb-4 sm:mb-6' : '')}>
        <div className={wrapInputClassCss}>
          {limit != null && (
            <span className={textLimitClassCss}>
              {value?.length ?? 0} / {limit}
            </span>
          )}
          <input
            className={`${inputClassCss} ${padding} ${
              type === 'password' ? ' pr-12' : ''
            }`}
            type={type === 'password' ? (show ? 'text' : 'password') : type}
            placeholder={placeholder}
            maxLength={limit}
            name={name}
            value={value}
            {...props}
          />
          {type === 'password' ? (
            <span
              className="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer text-gray-500"
              onClick={() => {
                setShow(!show);
              }}
            >
              {show ? (
                <EyeOffIcon className="h-5 " />
              ) : (
                <EyeOnIcon className="h-5 " />
              )}
            </span>
          ) : (
            ''
          )}
        </div>
        {typeof msg === 'string' ? (
          <span id={`${name ?? ''}_msg`} className="form-msg mt-2 inline-block">
            {msg}
          </span>
        ) : (
          ''
        )}
      </div>
    </>
  );
}

export default memo(forwardRef(Input));
