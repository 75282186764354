import React from 'react';

function WrapSkeleton({ children, ...props }: React.HTMLProps<HTMLDivElement>) {
  return (
    <div className="h-full rounded-md bg-white p-5" {...props}>
      {children}
    </div>
  );
}

export default WrapSkeleton;
