/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable tailwindcss/no-custom-classname */
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import Button from '../../components/Button';
import { useAppDispatch } from '../../redux/hook';
import { setProfile } from '../../redux/slice/authenticationSlice';
import { sendLogin } from '../../redux/storeService/authenticationService';
import { setAccessToken } from '../../shared/helpers/token';
import ArrowLeftIcon from '../../shared/icons/ArrowLeftIcon';
import UserIcon from '../../shared/icons/UserIcon';
import UserUnlock from '../../shared/icons/UserUnlock';
import { formData } from './other/data';
import { type LoginFormData, type ResetPassFormData } from './other/type';

function LoginForm() {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [formType, setFormType] = useState<'login' | 'reset'>('login');

  const [loading, setLoading] = useState(false);

  const form = formData[formType];

  const FormContext = form.formContext;

  const minPassword = 6;

  const schema = yup
    .object({
      email: yup
        .string()
        .trim()
        .required('Vui lòng nhập Email')
        .email('Vui lòng nhập Email hợp lệ'),
      ...(formType === 'login'
        ? {
            password: yup
              .string()
              .trim()
              .required('Vui lòng nhập mật khẩu')
              .min(minPassword, `Mật khẩu nhập ít nhất ${minPassword} ký tự`),
          }
        : {}),
    })
    .required();

  const method = useForm<LoginFormData | ResetPassFormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  // hook form
  const { handleSubmit, reset } = method;

  const onSubmit = async (values: LoginFormData | ResetPassFormData) => {
    if (formType === 'login') {
      const loadId = toast.loading('Đang gửi yêu cầu đăng nhập.');
      setLoading(true);

      try {
        const resData = await sendLogin(values as LoginFormData);
        setLoading(false);
        toast.dismiss(loadId);

        const {
          status_code: code,
          access_token: token,
          userData,
          message,
        } = resData;

        if (code === 200) {
          setAccessToken(token);
          dispatch(setProfile(userData ?? null));

          navigate('/admin');
        } else {
          toast.error(message ?? 'Lỗi hệ thống');
        }
      } catch (error) {
        console.error(error);
      }
    }

    return (e?: React.BaseSyntheticEvent) => {
      e?.preventDefault();
    };
  };

  return (
    <div className="relative w-[600px] rounded-xl bg-white py-6 px-8 md:py-12 md:px-20">
      <div
        className={
          'absolute left-1/2 -top-14 flex h-[96px] w-[96px] -translate-x-1/2 items-center justify-center rounded-full bg-white text-primary sm:h-[112px] sm:w-[112px]' +
          (formType === 'reset' ? ' bg-slate-50 p-4' : '')
        }
      >
        {formType === 'login' ? <UserIcon /> : <UserUnlock className="" />}
      </div>
      {formType === 'reset' && (
        <div
          className="absolute top-4 left-3 cursor-pointer sm:top-8 sm:left-6"
          onClick={() => {
            setFormType('login');
            reset();
            return true;
          }}
        >
          <ArrowLeftIcon className="h-6" />
        </div>
      )}
      <FormProvider {...method}>
        <form onSubmit={handleSubmit(onSubmit)} className="text-center">
          <div className="mb-2 mt-8 text-3xl font-extrabold text-darkMain sm:text-4xl">
            {form.title}
          </div>

          <div className="my-8 sm:mb-14">
            <FormContext />
          </div>
          <Button className="mb-4" disabled={loading}>
            {form.btnText}
          </Button>

          {formType === 'login' && (
            <span
              className="cursor-pointer font-bold transition-colors hover:text-primary"
              onClick={() => {
                reset();
                setFormType('reset');
              }}
            >
              Quên mật khẩu ?
            </span>
          )}
        </form>
      </FormProvider>
    </div>
  );
}

export default LoginForm;
