import React from 'react';
import Skeleton from 'react-loading-skeleton';

import WrapSkeleton from '../WrapSkeleton';

function CatalogListSkeleton() {
  return (
    <WrapSkeleton>
      {Array.from({ length: 8 }).map((_item, index) => (
        <div
          key={index}
          className="border-b-[1px] border-solid border-[#f4f4f5]"
        >
          <Skeleton
            containerClassName="flex gap-4 my-4"
            count={5}
            className="h-10"
          />
        </div>
      ))}
    </WrapSkeleton>
  );
}

export default CatalogListSkeleton;
