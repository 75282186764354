import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  type PayloadAction,
} from '@reduxjs/toolkit';
import { toast } from 'react-hot-toast';

import {
  getCatalogDetail,
  getCatalogList,
  removeCatalogImg,
  uploadImageCatalog,
} from '../storeService/catalogService';
import {
  type CatalogDetailNotFile,
  type CatalogRespData,
} from '../storeService/responseTypes';
import {
  type CatalogDetail,
  type CatalogRemoveImgReq,
  type CatalogReqType,
  type CatalogUploadImgReq,
} from '../type';

export interface InitialState {
  data: CatalogRespData | null;
  detail: CatalogDetail | null;
  loading: Record<string, boolean>;
  isError: boolean;
}

// async thunk
export const thunkGetCatalogList = createAsyncThunk(
  '@catalog/getCatalogList',
  async (reqData: CatalogReqType, thunkAPI) => {
    try {
      return await getCatalogList(reqData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const thunkGetCatalogDetail = createAsyncThunk(
  '@catalog/getCatalogDetail',
  async (id: number, thunkAPI) => {
    try {
      return await getCatalogDetail(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const thunkUploadCatalogImg = createAsyncThunk(
  '@catalog/uploadCatalogImg',
  async (data: CatalogUploadImgReq, thunkAPI) => {
    try {
      return await uploadImageCatalog(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const thunkRemoveCatalogImg = createAsyncThunk(
  '@catalog/removeCatalogImg',
  async (data: CatalogRemoveImgReq, thunkAPI) => {
    try {
      return await removeCatalogImg(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// reducer
export const initialState: InitialState = {
  data: null,
  detail: null,
  loading: {},
  isError: false,
};

export const catalogSlice = createSlice({
  name: '@catalog',
  initialState,
  reducers: {
    updateCatalogList: (state, action: PayloadAction<CatalogDetailNotFile>) => {
      state.data?.data.unshift(action.payload);
    },
    clearDetail: (state) => {
      state.detail = null;
    },
  },
  extraReducers: (builder) => {
    let loadId: string;
    let loadId2: string;

    builder
      // handler catalog list
      .addCase(thunkGetCatalogList.pending, (state) => {
        state.loading.getList = true;
      })
      .addCase(thunkGetCatalogList.fulfilled, (state, action) => {
        state.loading.getList = false;

        state.data = action.payload;
      })
      .addCase(thunkGetCatalogList.rejected, (state) => {
        state.loading.getList = false;
      })

      // handler catalog detaiL
      .addCase(thunkGetCatalogDetail.pending, (state) => {
        state.loading.getDetail = true;
      })
      .addCase(thunkGetCatalogDetail.fulfilled, (state, action) => {
        state.loading.getDetail = false;

        state.detail = action.payload;
      })
      .addCase(thunkGetCatalogDetail.rejected, (state) => {
        state.loading.getDetail = false;
      })

      // handler upload catalog img
      .addCase(thunkUploadCatalogImg.pending, () => {
        loadId2 = toast.loading('Đang tải lên');
      })
      .addCase(thunkUploadCatalogImg.fulfilled, (state, action) => {
        const dataResp = action.payload.data;
        const typeResp = action.payload.type;

        if (state?.detail != null) {
          if (['images', 'file'].includes(typeResp)) {
            state.detail.images = dataResp.images;
          } else {
            state.detail[typeResp as 'image' | 'share_image'] =
              dataResp[typeResp as 'image' | 'share_image'];
          }
        }

        const imgRadio = document.getElementById(
          'image_radio'
        ) as HTMLInputElement | null;

        if (imgRadio != null && typeResp === 'file') {
          imgRadio.click();
        }

        toast.success(action.payload.message);
        toast.dismiss(loadId2);
      })
      .addCase(thunkUploadCatalogImg.rejected, () => {
        toast.dismiss(loadId2);
      })

      // handler remove catalog img
      .addCase(thunkRemoveCatalogImg.pending, () => {
        loadId = toast.loading('Đang xóa ảnh');
      })
      .addCase(thunkRemoveCatalogImg.fulfilled, (state, action) => {
        toast.success(action.payload.message);

        if (state?.detail?.images != null) {
          const newImages = state.detail.images.filter(
            (item) => !action.payload.dataRemove.includes(item)
          );

          state.detail.images = newImages;
        }
      })
      .addMatcher(
        isAnyOf(
          thunkRemoveCatalogImg.fulfilled,
          thunkRemoveCatalogImg.rejected
        ),
        () => {
          toast.dismiss(loadId);
        }
      );
  },
});

export const { reducer, actions } = catalogSlice;

export const { clearDetail, updateCatalogList } = actions;

export default reducer;
